<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/inventory-counts/list']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Inventory Count Details</span>
        </h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-down"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-up"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Inventory Count Details</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content"
      *ngIf="countService.inventoryCount | async; let count">
      <div class="row">
        <div class="col-md-12"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">Inventory Count - <strong>{{count.date | date:'longDate'}}</strong></h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openInventoryCountModal(count.inventoryCountId)"
                  [disabled]="disableFinalize || !isInProgress"><i class="fa fa-edit"></i><span *ngIf="!isTablet">
                    Edit</span></button>
              </div>
            </div>
            <div class="card-body pt-2">
              <div class="row">
                <div class="col-md-5 border-right">
                  <dl class="row">
                    <dt class="col-sm-2 text-right"
                      [ngClass]="{'pr-0':isTablet}">Employee:</dt>
                    <dd class="col-sm-10 text-left mb-2">{{count.employeeName}}</dd>
                    <dt class="col-sm-2 text-right"
                      [ngClass]="{'pr-0':isTablet}">Status:</dt>
                    <dd class="col-sm-10 text-left mb-2">
                      <span class="badge"
                        [ngClass]="{'bg-primary': count.inventoryCountStatusId === InventoryCountStatuses.InProgress, 'bg-dark': count.inventoryCountStatusId === InventoryCountStatuses.Complete }">{{count.inventoryCountStatusName}}</span>
                    </dd>
                    <dt class="col-sm-2 text-right"
                      [ngClass]="{'pr-0':isTablet}">Notes:</dt>
                    <dd class="col-sm-10 text-left mb-2">
                      <span *ngIf="count.notes">{{count.notes}}</span>
                      <span *ngIf="!count.notes"
                        class="text-muted">N/A</span>
                    </dd>
                    <dt class="col-sm-2 text-right"
                      [ngClass]="{'pr-0':isTablet}">Total Diff:</dt>
                    <dd class="col-sm-10 text-left mb-2"
                      [ngClass]="{ 'text-danger': diffs[2] < 0, 'text-success': diffs[2] > 0 }">
                      <span><strong>{{diffs[2] | currency}}</strong></span>
                    </dd>
                    <dt class="col-sm-2 text-right"
                      [ngClass]="{'pr-0':isTablet}">Pos. Diff:</dt>
                    <dd class="col-sm-10 text-left mb-2"
                      [ngClass]="{ 'text-danger': diffs[1] < 0, 'text-success': diffs[1] > 0 }">
                      <span>{{diffs[1] | currency}}</span>
                    </dd>
                    <dt class="col-sm-2 text-right"
                      [ngClass]="{'pr-0':isTablet}">Neg. Diff:</dt>
                    <dd class="col-sm-10 text-left mb-2"
                      [ngClass]="{ 'text-danger': diffs[0] < 0 }">
                      <span>{{diffs[0] | currency}}</span>
                    </dd>
                  </dl>
                </div>
                <div class="col-md-7">
                  <div class="row">
                    <div class="col-md-6 pl-4">
                      <div class="form-group mb-0">
                        <label class="col-form-label pt-0">Part Family</label>
                        <div *ngIf="partFamilyService.partFamilyList | async; let pf">
                          <ng-select [items]="pf"
                            bindLabel="name"
                            bindValue="partFamilyId"
                            [(ngModel)]="countItemService.filterDto.partFamilyId"
                            (change)="onChangePartFamily($event)"
                            name="partFamily"
                            appendTo='body'>
                          </ng-select>
                        </div>
                        <div *ngIf="!partFamilyService.partFamilyList.value"
                          [ngClass]="{'pl-0':isTablet}">
                          <ng-select name="partFamily"
                            [readonly]="true">
                          </ng-select>
                        </div>
                        <label class="col-form-label">Part Group</label>
                        <div *ngIf="partGroupService.partGroupList | async; let pg">
                          <ng-select [items]="pg"
                            bindLabel="name"
                            bindValue="partGroupId"
                            [(ngModel)]="countItemService.filterDto.partGroupId"
                            name="partGroup"
                            appendTo='body'
                            (change)="onChangePartGroup($event)">
                          </ng-select>
                        </div>
                        <div [ngClass]="{'pl-0':isTablet}"
                          *ngIf="(partGroupService.partGroupList | async)===null; let pg">
                          <ng-select name="partGroup"
                            [readonly]="true">
                          </ng-select>
                        </div>
                        <label class="col-form-label">Search</label>
                        <input type="text"
                          class="form-control  form-control-sm"
                          [(ngModel)]="countItemService.filterDto.searchTerm"
                          name="ponumberFilter"
                          (keyup.enter)="countItemService.getInventoryCountItems(inventoryCountId, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"
                          (blur)="countItemService.getInventoryCountItems(inventoryCountId, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label class="col-form-label pt-0">Counted Only</label>
                        <div>
                          <ui-switch [ngModel]="countItemService.filterDto.countedOnly"
                            (change)="onChangeCountedOnly($event)"></ui-switch>
                        </div>
                        <label class="col-form-label pt-3">Uncounted Only</label>
                        <div>
                          <ui-switch [ngModel]="countItemService.filterDto.uncountedOnly"
                            (change)="onChangeUncountedOnly($event)"></ui-switch>
                        </div>
                        <div class="row position-absolute bottom-0 end-0 w-100">
                          <div class="col">
                            <button type="submit"
                              class="btn btn-sm btn-block btn-light mr-1"
                              (click)="countItemService.clearFilterParams(showInactive, inventoryCountId);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize });this.partGroupService.partGroupList.next(null);"><i
                                class="far fa-times-circle"></i>&nbsp;Clear</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Inventory Counts</h6>
              <div class="header-elements">
                <button (click)="generateDetailReport()"
                  class="mr-2 btn btn-sm btn-light"><i class="fas fa-file-excel"></i>&nbsp;&nbsp;Export Summary</button>
                <button class="btn btn-sm btn-danger"
                  [swal]="{ title: 'Finalize Count?', text: 'This action will alter inventory records and cannot be undone!', showCancelButton: true, confirmButtonText: 'Finalize', confirmButtonColor: '#DC3545' }"
                  (confirm)="finishInventoryCount()"
                  [disabled]="disableFinalize || !isInProgress"><i class="far fa-tally"></i>&nbsp;&nbsp;Finalize
                  Count</button>
              </div>
            </div>
            <app-table-skeleton *ngIf="countItemService.countIsLoading"></app-table-skeleton>
            <ng-container *ngIf="!countItemService.countIsLoading">
              <div class="table-responsive"
                *ngIf="countItemService.inventoryCountItemList | async; let model">
                <kendo-grid [style.maxHeight.px]="640"
                  [kendoGridBinding]="model"
                  (excelExport)="onExcelExport($event)"
                  filterable="menu"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  [pageSize]="kGridParams.pageSize"
                  [skip]="kGridParams.skip"
                  (pageChange)="pageChange($event)"
                  [pageable]="{
                    buttonCount: kGridParams.buttonCount,
                    info: kGridParams.info,
                    type: kGridParams.type,
                    pageSizes: kGridParams.pageSizes,
                    previousNext: kGridParams.previousNext
                  }">
                  <!-- <ng-template kendoGridToolbarTemplate>
                    <button type="button"
                      kendoGridExcelCommand
                      icon="file-excel">Export to Excel</button>
                  </ng-template> -->
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/items/details', dataItem.itemId]"><i class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="partNumber"
                    title="Part Number"
                    width="100">
                  </kendo-grid-column>
                  <kendo-grid-column field="itemDescription"
                    title="Item">
                  </kendo-grid-column>
                  <kendo-grid-column field="manualCount"
                    title="Manual Count"
                    width="110">
                    <ng-template kendoGridCellTemplate
                      let-dataItem>
                      <input type="number"
                        step=".01"
                        class="form-control form-control-sm"
                        id="manualCount_{{dataItem.inventoryCountId}}"
                        [(ngModel)]="dataItem.manualCount"
                        (blur)="updateItemCount($event, dataItem)"
                        (keyup.enter)="updateItemCount($event, dataItem)"
                        [readonly]="!isInProgress">
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="tackleboxCount"
                    title="Tacklebox Count"
                    width="130">
                  </kendo-grid-column>
                  <kendo-grid-column field="difference"
                    title="Difference"
                    width="100">
                    <ng-template kendoGridCellTemplate
                      let-dataItem>
                      <span
                        [ngClass]="{ 'text-danger' : dataItem.difference < 0, 'text-success' : dataItem.difference > 0 }">{{dataItem.difference}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="tackleboxCost"
                    title="Cost"
                    width="100">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.tackleboxCost | currency}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="costDifference"
                    title="Cost Difference"
                    width="110">
                    <ng-template kendoGridCellTemplate
                      let-dataItem>
                      <span
                        [ngClass]="{ 'text-danger' : dataItem.costDifference < 0, 'text-success' : dataItem.costDifference > 0 }">{{dataItem.costDifference
                        | currency}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-excel fileName="Grizzly_Inventory_Count.xlsx"
                    [fetchData]="allData">
                    <kendo-excelexport-column field="partNumber"
                      title="Part #">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="itemDescription"
                      title="Item">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="manualCount"
                      title="Manual Count">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="tackleboxCount"
                      title="Tacklebox Count">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="difference"
                      title="Difference">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="tackleboxCost"
                      title="Cost"
                      [cellOptions]="{ format: '$0.00' }">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="costDifference"
                      title="Cost Difference"
                      [cellOptions]="{ format: '$0.00' }">
                    </kendo-excelexport-column>
                  </kendo-grid-excel>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
