import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemService } from './item.service';
import { UserService } from './user.service';
import { InventoryCountItemModel } from 'src/models/inventory-count-item.model';
import { InventoryItemCountSearchParamsModel } from 'src/models/RequestParams/inventory-item-count-search-params.model';

@Injectable()
export class InventoryCountItemService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public itemService: ItemService,
    public userService: UserService
  ) { }

  public inventoryCountItemList: BehaviorSubject<Array<InventoryCountItemModel>> = new BehaviorSubject(null);
  public differenceCounts: BehaviorSubject<[negativeDiff: number, positiveDiff: number, totalDiff: number]> = new BehaviorSubject(null);
  public filterDto: InventoryItemCountSearchParamsModel = new InventoryItemCountSearchParamsModel();
  public setToFirstPage: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public countIsLoading: boolean = false;

  private apiUrl = 'api/InventoryCountItem';  // URL to web api

  public getInventoryCountItems(
    inventoryCountId: number,
    showSkeleton: boolean = false): void {

    if (showSkeleton) {
      this.countIsLoading = true;
    }

    const url = this.baseUrl + this.apiUrl + '/GetInventoryCountItems/' + inventoryCountId;

    this.http.post<Array<InventoryCountItemModel>>(url, this.filterDto)
      .subscribe((x: InventoryCountItemModel[]) => {
        this.inventoryCountItemList.next(x);

        this.countIsLoading = false;

        this.setToFirstPage.next(true);

        this.updateInventoryValues();
      });
  }

  public updateInventoryValues(): void {
    let negativeDifference: number = 0;
    let positiveDifference: number = 0;
    let totalDifference: number = 0;

    if (this.inventoryCountItemList.value) {
      this.inventoryCountItemList.value.forEach((x: InventoryCountItemModel) => {
        if (x.costDifference < 0) {
          negativeDifference += x.costDifference;
        } else if (x.costDifference > 0) {
          positiveDifference += x.costDifference;
        }
        totalDifference += x.costDifference;
      });
    }

    this.differenceCounts.next([negativeDifference, positiveDifference, totalDifference]);
  }

  public updateItemCount(dto: InventoryCountItemModel): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + this.apiUrl + '/UpdateItemCount', dto);
  }

  public clearFilterParams(showInactive: boolean, inventoryCountId: number): void {
    Object.keys(this.filterDto).forEach((index: string) => {
      if (index !== 'countedOnly' && index !== 'uncountedOnly') {
        this.filterDto[index] = null;
      } else {
        this.filterDto[index] = false;
      }
    });

    this.getInventoryCountItems(inventoryCountId);
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.filterDto).forEach((value: any) => {

      count = value !== null && value !== false && (!Array.isArray(value) || (Array.isArray(value) && value.length > 0)) ? count += 1 : count;
    });

    return count;
  }

}
