import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ExcelExportEvent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InventoryCountStatuses } from 'src/enums/inventory-count-statuses';
import { PartFamilyModel } from 'src/models/part-family.model';
import { PartGroupModel } from 'src/models/part-group.model';
import { InventoryCountItemService } from 'src/services/inventory-count-item.service';
import { InventoryCountStatusService } from 'src/services/inventory-count-status.service';
import { InventoryCountService } from 'src/services/inventory-count.service';
import { KGridService } from 'src/services/k-grid.service';
import { PartFamilyService } from 'src/services/part-family.service';
import { PartGroupService } from 'src/services/part-group.service';
import { UIService } from 'src/services/ui.service';
import { AddEditInventoryCountModalComponent } from '../add-edit-inventory-count-modal/add-edit-inventory-count-modal.component';
import { InventoryCountItemModel } from 'src/models/inventory-count-item.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { InventoryCountModel } from 'src/models/inventory-count.model';
import { ReportService } from 'src/services/report.service';

@Component({
  selector: 'app-inventory-counts-list',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class InventoryCountDetailsComponent implements OnInit, OnDestroy {

  private routeParamsSub: Subscription;
  private inventoryCountId: number = -1;
  public showFilter = true;
  public numberOfActiveFilterFields: number = 0;
  public disableFinalize: boolean = false;

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: [10, 20, 50, 100, 'All'],
      previousNext: true,
      pageSize: 100,
      skip: 0
    };

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public statusService: InventoryCountStatusService,
    public countService: InventoryCountService,
    public countItemService: InventoryCountItemService,
    public uiService: UIService,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    public partFamilyService: PartFamilyService,
    public partGroupService: PartGroupService,
  ) {
    this.allData = this.allData.bind(this);
  };

  public bsModalRef: BsModalRef;

  private deleteSub: Subscription;
  private diffSub: Subscription;
  private reportSub: Subscription;
  private statusSub: Subscription;
  private countSub: Subscription;
  private finalizeSub: Subscription;
  private firstPageSub: Subscription;
  private updateSub: Subscription;
  public InventoryCountStatuses = InventoryCountStatuses;
  public isInProgress: boolean = false;
  public count: InventoryCountModel;
  public diffs: [negativeDiff: number, positiveDiff: number, totalDiff: number] = [0, 0, 0];

  ngOnDestroy() {
    this.routeParamsSub?.unsubscribe();

    this.kGridService.resetKGridParams();

    this.countSub?.unsubscribe();

    this.deleteSub?.unsubscribe();

    this.diffSub?.unsubscribe();

    this.finalizeSub?.unsubscribe();

    this.firstPageSub?.unsubscribe();

    this.reportSub?.unsubscribe();

    this.statusSub?.unsubscribe();

    this.updateSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToRouteParams();

    this.subToCount();

    this.subToDiffCounts();

    this.subToSetToFirstPage();

    this.partFamilyService.refreshPartFamilyList(
      false,
      true,
      false);

    this.partGroupService.partGroupList.next(null);
  }

  public subscribeToRouteParams(): void {
    this.countService.inventoryCountList.next(null);

    this.routeParamsSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.inventoryCountId = parseInt(this.activatedRoute.snapshot.params['id']);

        if (this.countItemService.filterDto.partFamilyId) {
          this.partGroupService.getPartGroupsByFamilyId(
            this.countItemService.filterDto.partFamilyId,
            false,
            true);
        }

        this.countService.refreshCount(this.inventoryCountId, true);
        this.countItemService.getInventoryCountItems(this.inventoryCountId, true);
      });
  }

  public generateDetailReport(): void {
    this.uiService.showToastGeneratingExcel();

    this.reportSub = this.reportService.generateCountDetailReview(this.inventoryCountId)
      .subscribe(x => {
        this.uiService.killAllToastMessages();

        if (!x) {
          this.uiService.showError('Error generating report', 'Error');
        }
      });
  }

  public subToCount(): void {
    this.countSub = this.countService.inventoryCount
      .subscribe(x => {
        if (x) {
          this.isInProgress = x.inventoryCountStatusId === InventoryCountStatuses.InProgress;
          this.count = x;
        }
      });
  }

  public subToDiffCounts(): void {
    this.diffSub = this.countItemService.differenceCounts
      .subscribe((x: [negativeDiff: number, positiveDiff: number, totalDiff: number]) => {
        if (x) {
          this.diffs = x;
        }
      });
  }

  public subToSetToFirstPage(): void {
    this.firstPageSub = this.countItemService.setToFirstPage
      .subscribe(x => {
        if (x) {
          this.setToFirstPage();
        }
      });
  }

  public onChangePartFamily($event: PartFamilyModel): void {
    this.countItemService.filterDto.partGroupId = null;

    if ($event !== undefined && $event !== null) {
      this.partGroupService.getPartGroupsByFamilyId(
        $event.partFamilyId,
        false,
        true);
    } else {
      this.partGroupService.partGroupList.next(null);
    }

    this.countItemService.getInventoryCountItems(this.inventoryCountId, false);
  }

  public finishInventoryCount(): void {
    this.disableFinalize = true;

    this.uiService.showToastSaving();

    this.countService.finishInventoryCount(this.inventoryCountId)
      .subscribe(x => {
        this.uiService.killAllToastMessages();

        if (x) {
          this.uiService.showSuccess('Inventory Count Finalized', 'Success');
        } else {
          this.uiService.showError('Inventory Count Finalized', 'Error');
        }

        this.disableFinalize = false;
      });
  }

  public onChangePartGroup($event: PartGroupModel): void {
    this.countItemService.getInventoryCountItems(this.inventoryCountId, false);
  }

  public onExcelExport($event: ExcelExportEvent) {
    let d = new Date(this.count.date);

    let workbook = $event.workbook;

    workbook.sheets[0].name = `${d.toDateString()}`;
  }

  public onChangeCountedOnly($event: boolean): void {
    if ($event !== undefined
      && $event !== null) {
      let filter = this.countItemService.filterDto;

      filter.countedOnly = $event;

      if ($event) {
        filter.uncountedOnly = false;
      }

      this.countItemService.getInventoryCountItems(this.inventoryCountId, false);

    }
  }

  public onChangeUncountedOnly($event: boolean): void {
    if ($event !== undefined
      && $event !== null) {
      let filter = this.countItemService.filterDto;

      filter.uncountedOnly = $event;

      if ($event) {
        filter.countedOnly = false;
      }

      this.countItemService.getInventoryCountItems(this.inventoryCountId, false);
    }
  }

  public openInventoryCountModal(inventoryCountId: number): void {
    const initialState = {
      id: inventoryCountId,
    };

    this.bsModalRef = this.modalService.show(AddEditInventoryCountModalComponent, { initialState, backdrop: 'static' });
  }

  public updateItemCount($event, dataItem: InventoryCountItemModel): void {
    if (dataItem) {
      if (dataItem.manualCount !== null && dataItem.tackleboxCount !== null) {
        dataItem.difference = dataItem.manualCount - dataItem.tackleboxCount;
      } else if (dataItem.manualCount !== null && dataItem.tackleboxCount === null) {
        dataItem.difference = dataItem.manualCount;
      } else if (dataItem.manualCount === null && dataItem.tackleboxCount !== null) {
        dataItem.difference = null;
      }

      if (dataItem.difference !== null && dataItem.tackleboxCost) {
        dataItem.costDifference = dataItem.difference * dataItem.tackleboxCost;
      }

      this.countItemService.updateInventoryValues();

      this.updateSub = this.countItemService.updateItemCount(dataItem)
        .subscribe(x => {
          if (!x) {
            this.uiService.showError('Error updating item count', 'Error');

            this.countItemService.updateInventoryValues();
          }
        });
    }
  }

  public setToFirstPage(): void {
    this.kGridParams.skip = 0;
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

  public getNumberOfActiveFilterFields() {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.countItemService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

  public allData(): ExcelExportData {
    let sev = this.countItemService.inventoryCountItemList.value;

    const result: ExcelExportData = {
      data: process(sev, { group: null, sort: null }).data,
      group: null
    };

    return result;
  }

}
